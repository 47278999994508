import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  // Function to handle smooth scrolling
  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    setIsOpen(false); // Close menu after selecting an item
  };

  return (
    <nav className="text-white py-4">
      <div className="max-w-3xl mx-auto px-4 flex items-center justify-between">
        <div className="text-lg font-bold">Mutia's Portfolio</div>

        {/* Hamburger Icon */}
        <div className="block lg:hidden relative">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
          {/* Dropdown Menu */}
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="absolute top-full right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg"
            >
              <button
                onClick={() => scrollToSection('about')}
                className="block px-4 py-2 text-white hover:bg-gray-700"
              >
                About
              </button>
              <button
                onClick={() => scrollToSection('experience')}
                className="block px-4 py-2 text-white hover:bg-gray-700"
              >
                Experience
              </button>
              <button
                onClick={() => scrollToSection('certificates')}
                className="block px-4 py-2 text-white hover:bg-gray-700"
              >
                Certificates
              </button>
              <button
                onClick={() => scrollToSection('helpfull')}
                className="block px-4 py-2 text-white hover:bg-gray-700"
              >
               Helpfull 
              </button>
            </motion.div>
          )}
        </div>

        {/* Navbar Links (for large screens) */}
        <div className={`lg:flex items-center space-x-4 ${isOpen ? 'hidden' : 'hidden lg:flex'}`}>
          <button
            onClick={() => scrollToSection('about')}
            className="hover:text-gray-400"
          >
            About
          </button>
          <button
            onClick={() => scrollToSection('experience')}
            className="hover:text-gray-400"
          >
            Experience
          </button>
          <button
            onClick={() => scrollToSection('certificates')}
            className="hover:text-gray-400"
          >
            Certificates
          </button>
          <button
            onClick={() => scrollToSection('helpfull')}
            className="hover:text-gray-400"
          >
            Helpfull
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
