import React from 'react';
import { motion } from 'framer-motion';
import Guestbook from './Guestbook';
import VisitorCounter from './VisitorCounter';
import Navbar from './Navbar';

const Portfolio = () => {
  return (
    <div className="bg-black text-white min-h-screen p-5 font-poppins">
      <Navbar />
      <div className="mt-5 max-w-3xl mx-auto px-4"> {/* Updated max-width and padding */}
        <div className="flex items-center space-x-4"> {/* Adjusted spacing */}
          <motion.img
            src="aku2.jpeg"
            alt="Profile"
            className="w-24 h-24 rounded-full"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          />
          <div>
            <motion.h1
              className="text-1xl font-bold font-poppins flex items-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Mutia Pegi Intanswari
              <i className="fas fa-check-circle text-blue-500 ml-2"></i>
            </motion.h1>

            <motion.p
              className="text-base text-gray-400"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Indonesia - Metro Lampung
            </motion.p>
            <motion.a
              href="https://mutiadev.site"
              className="text-blue-500 text-sm"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              mutiadev.site
            </motion.a>
          </div>
        </div>
        <section id="coding-experience" className="mt-10">
          <motion.h2
            className="text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
           
          </motion.h2>

          <div className="flex justify-around mt-6">
            <motion.div
              className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full w-24 h-24 flex items-center justify-center text-center"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div>
                <p className="text-white text-lg font-bold ">3+</p>
                <p className="text-white text-sm font-poppins">Years of Coding</p>
              </div>
            </motion.div>

            <motion.div
              className="bg-gradient-to-r from-green-400 via-teal-500 to-blue-500 rounded-full w-24 h-24 flex items-center justify-center text-center"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div>
                <p className="text-white text-lg font-bold ">76+</p>
                <p className="text-white text-sm font-poppins">Completed Projects</p>
              </div>
            </motion.div>

            <motion.div
              className="bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 rounded-full w-24 h-24 flex items-center justify-center text-center"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <div>
                <p className="text-white text-lg font-bold ">560+</p>
                <p className="text-white text-sm font-poppins">Hours of Coding</p>
              </div>
            </motion.div>
          </div>
        </section>



        <section id="about" className="mt-10">
          <motion.h2
            className="text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            About Me
          </motion.h2>
          <motion.p
            className="mt-2 text-base text-gray-600 font-semibold font-poppins"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Hi, I'm Mutia Pegi Intanswari, a Software Engineering student at SMK NEGERI 3 METRO. I love coding and creating impactful tech solutions. Whether it's building web apps, managing databases, or designing user-friendly interfaces, I'm all in for delivering quality work.
          </motion.p>

        </section>

        <section id="experience" className="mt-10">
          <motion.h2
            className="text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Freelance Experience 📑
          </motion.h2>

          {/* Freelance Experience Items */}
          <motion.div
            className="mt-6 space-y-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div>
              <h3 className="text-lg font-semibold text-gray-400">Arcane Project Technology 2024</h3>
              <p className='mt-2 text-gray-600 text-base font-semibold'>As the owner of Arcane Project Technology, I specialize in web development and graphic design. My role involves creating visually stunning and highly functional websites that enhance user experience and engagement. With a deep understanding of both front-end and back-end technologies, I deliver customized solutions that meet the specific needs of clients.</p>
              <p className="text-sm mt-1 text-gray-400">React • Laravel • Databases</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-gray-400">Bot Developer 2021-2023</h3>
              <p className='mt-2 text-gray-600 text-base font-semibold'>I develop advanced bots for WhatsApp that streamline various user needs, including e-commerce functionalities and interactive gaming experiences. My bots are designed to simplify tasks and provide a seamless user experience through automation and integration with external services.</p>
              <p className="text-sm mt-1 text-gray-400">JavaScript • NodeJS • Scraper • API</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-gray-400">Hosting Provide 2022-2023</h3>
              <p className='mt-2 text-gray-600 text-base font-semibold'>I offer high-quality web hosting services for game servers and websites, providing robust and reliable hosting solutions at affordable prices. My services are tailored to ensure optimal performance and uptime, catering to the diverse needs of clients.</p>
              <p className="text-sm mt-1 text-gray-400">Pterodactyl • NodeJs</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-gray-400">Discord Server Creation Service 2023</h3>
              <p className='mt-2 text-gray-600 text-base font-semibold'>I create and manage sophisticated and aesthetically pleasing Discord servers that align with users' preferences and requirements. Whether it’s for community engagement, gaming, or professional purposes, I ensure that each server is customized to provide the best experience for its members.</p>
              <p className="text-sm mt-1 text-gray-400">Discord • Music</p>
            </div>
          </motion.div>
        </section>

        <section className="mt-10">
          <motion.h2
            className="text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Achievement 🏆
          </motion.h2>
          <motion.div
            className="text-base mt-1 text-gray-600 font-semibold"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <p> • LKS Web Tech City</p>
            <p> • LKS Web Tech Province</p>
            <p> • LKS Web Tech National</p>
          </motion.div>
        </section>

        {/* Single Certificate Section */}
        <section id="certificates" className="mt-10">
          <motion.h3
            className="text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Certificate
          </motion.h3>
          <div className="mt-4">
            <motion.img
              src="sertifikat1.png"
              alt="Certificate"
              className="w-full h-auto rounded-lg"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />

          </div>
          <div id="helpfull" className="mt-10 p-6 rounded-sm">
            <h2 className="text-2xl font-semibold text-white flex items-center">
              <span>🌐</span> &nbsp;Helpful Links
            </h2>
            <div className="mt-4">
              <motion.div
                className="mb-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h3 className="text-lg text-white">Docs NodeJS 👇🏻</h3>
                <div className="p-4 border rounded-lg mt-2 flex items-center justify-between">
                  <div>
                    <p className="text-white font-semibold">
                      API reference and usage guidance.
                    </p>
                    <p className="text-gray-400 text-sm">See more</p>
                    <a href="https://nodejs.org/en/docs/" className="text-blue-400 hover:underline text-sm">
                      https://nodejs.org/en/docs/
                    </a>
                  </div>
                </div>
              </motion.div>

              <motion.div
                className="mb-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <h3 className="text-lg text-white">React JS Roadmap 👇🏻</h3>
                <div className="p-4 border rounded-lg mt-2 flex items-center justify-between">
                  <div>
                    <p className="text-white font-semibold"> React application development.</p>
                    <p className="text-gray-400 text-sm">roadmap for reactjs</p>
                    <a href="https://roadmap.sh/react" className="text-blue-400 hover:underline text-sm">
                      https://roadmap.sh/react
                    </a>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <h3 className="text-lg text-white">Laravel Roadmap 👇🏻</h3>
                <div className="p-4 border rounded-lg mt-2 flex items-center justify-between">
                  <div>
                    <p className="text-white font-semibold">Laravel Roadmap | LaravelDaily</p>
                    <p className="text-gray-400 text-sm">See the big picture</p>
                    <a href="https://laraveldaily.com/roadmap-learning-path" className="text-blue-400 hover:underline text-sm">
                      https://laraveldaily.com/learning-path
                    </a>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Guestbook Section */}
        <section className="mt-10">
          <motion.h3
            className="text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Visitor's Logs
          </motion.h3>
          <VisitorCounter />
          <Guestbook />
        </section>
      </div>

      {/* Footer Section */}
      <footer className="text-gray-400 py-4 mt-10">
        <div className="max-w-3xl mx-auto text-center">
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            &copy; {new Date().getFullYear()} Mutia Pegi Intanswari. All rights reserved.
          </motion.p>
          <motion.div
            className="mt-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <a href="https://www.linkedin.com/in/mutia-pegi-intanswari-184597298/" className="text-gray-400 hover:text-white mx-2">
              LinkedIn
            </a>
            <a href="mailto:mutiacode@gmail.com" className="text-gray-400 hover:text-white mx-2">
              Email
            </a>
          </motion.div>



        </div>
      </footer>
    </div>
  );
}

export default Portfolio;
