import React from 'react';
import Portfolio from './Portfolio';
import './App.css';

function App() {
  return (
    <div>
      <Portfolio />
    </div>
  );
}

export default App;
